@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "UnivaNova-Regular";
  src: url("./UnivaNova-Regular.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "UnivaNova-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.show {
  display: block;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease;
}

.my-word-btn:hover .arrow-icon {
  transform: rotate(90deg);
}

.section-header {
  font-family: Arial, Helvetica, sans-serif;
}
